.app-nav {
    .portal-info {
        height: 60px;
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        font-size: 15px;

        .branding-logo {
            width: 125px;
            height: 50px;
            padding-top: 10px;
            margin-right: 20px;

            img {
                height: 40px;
            }
        }
        .nav-bar-icons {
            // border-left: 1px solid white;
            @media (max-width: 767px) {
                display: none !important;
            }
            @media (max-width: 400px) {
                display: none !important;
            }
        }
        .info {
            font-size: 90%;
            display: flex;
            flex: 1 0 calc(20% - 10px);
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;

            .icon {
                margin-top: 5px;
                margin-right: 10px;
            }

            .key-value {
                text-align: left;

                label:first-child {
                    text-transform: uppercase;
                }

                label.value {
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }
    .nav-toobar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .active-link {
        .MuiListItem-button {
            color: #f50057 !important;
        }
    }
    .sidebar-icon {
        min-width: 35px !important;
        // color: red;
    }
    .text-and-icon {
        color: white;
        text-decoration: none;
        .MuiSvgIcon-root {
            font-size: 1.3rem;
        }
    }
    .component-container {
        .custom-scroll {
            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }
}

.mt-20 {
    margin-top: 20px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.height-58 {
    height: 58px;
}

#map {
    height: 100%;
}
