.controller-screen {
    .normalLineHeight {
        line-height: normal;
    }
    .edit-controller-btn {
        margin: 0px 0px 10px 0px !important;
        .btn-m {
            margin-right: 5px !important;
        }
        .MuiButton-root {
            text-transform: none !important;
            font-size: 0.7rem;
        }
    }
    .dash-controller-btn {
        margin: 0px 0px 10px 0px !important;
        .btn-m {
            margin-right: 3px !important;
            max-height: 32px !important;
            min-height: 32px !important;
        }
        .MuiButton-root {
            text-transform: none !important;
            font-size: 0.7rem;
        }
    }
    .controller-label {
        .MuiTypography-caption {
            color: gray;
        }
    }
    .mt_10 {
        margin-top: 10px !important;
    }
    .mt_11 {
        margin-top: 13px !important;
    }
    .mtl {
        margin-top: 10px !important;
        margin-left: 5px !important;
    }
    .customer_label {
        margin-left: 5px !important;
    }
    .customer_label_data {
        margin-left: 5px !important;
    }
    .checkbox-font {
        .MuiTypography-body1 {
            font-size: 0.8rem;
        }
    }
    .grid-paper-shadow {
        .MuiPaper-elevation1 {
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2) !important;
        }
        .controller-container {
            padding: 8px 8px !important;
            display: flex;
            overflow: auto;
            flex-direction: column;
        }
    }
    .btn-text {
        .MuiButton-root {
            text-transform: none !important;
            font-size: 0.7rem;
        }
    }
    .btn-hw {
        .MuiButton-root {
            font-size: 0.75rem;
            text-transform: none !important;
        }
        .MuiButton-fullWidth {
            width: 150px;
            margin: 10px 16px 4px 0px;
            height: 45px;
        }
    }
    @media screen and (max-width: 1450px) {
        .switchBtnContainer {
            // background-color: orange;
            position: relative;
            right: 0;
            top: 0;
            justify-content: flex-start;
            width: 100%;
        }
        .childSwitchBtnContainer {
            display: flex;
            justify-content: start;
            margin-right: 15px;
        }
    }
    @media screen and (min-width: 1450px) {
        .switchBtnContainer {
            // background-color: pink;
            position: relative;
            right: 0;
            top: 0;
            justify-content: flex-end;
            // width: 60%;
        }
        .childSwitchBtnContainer {
            display: flex;
            justify-content: flex-end;
            margin-right: 15px;
        }
    }
}

.sigContainer {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.sigPad {
    width: 100%;
    height: 100%;
}

.grid-style {
    outline: 1px !important;
    outline-style: solid !important;
    outline-color: rgba(224, 224, 224, 1) !important;
    padding-left: 5px !important;
}

.gray-grid {
    background-color: #f9f9f9 !important;
}

.white-grid {
    background-color: #ffffff !important;
}

.grid-title-row {
    margin: 5px 5px 5px 0px !important;
    .MuiButton-root {
        text-transform: none !important;
        font-size: 0.65rem;
    }
}
