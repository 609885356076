.input-style {
    .input_label {
        color: gray !important;
    }
    .input_value {
        background-color: rgb(245, 249, 249) !important;
        padding: 8.5px 18.5px 10.5px 14px;
    }
}
.input-style_right {
    .input_label {
        color: gray !important;
    }
    .input_value {
        background-color: rgb(245, 249, 249) !important;
        padding: 8.5px 18.5px 10.5px 14px;
    }
}
