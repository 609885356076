.screen {
    overflow: hidden;
    margin: 10px 20px;

    .add_btn {
        position: absolute;
        top: 80px;
        right: 28px;
    }
}

.submit_btn {
    margin-bottom: 17px !important;
}

.react-data-grid-cell-line-height {
    line-height: normal;
}

.mandatory-fields {
    .MuiInputLabel-animated {
        color: red !important;
    }
}
.gridadd-btn {
    overflow: hidden;
    .MuiButton-root {
        top: -1px !important;
        font-size: 0.7rem;
        text-transform: none;
        padding-top: 10.7px;
        display: inline-flex !important;
        padding-bottom: 10.7px;
    }
    .MuiButton-contained {
        box-shadow: none;
    }
}
