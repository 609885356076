.Customer-screen {
    .edit-customer-btn {
        margin: 0px 0px 10px 0px !important;
    }
    .customer_label {
        .MuiTypography-caption {
            color: gray;
        }
    }
}
