.modal-btn {
    margin: 0px 10px !important;
    .MuiButton-root {
        min-width: 30px !important;
        padding: 4px 0px;
    }
}

.modal-btn-update,
.custom-icon-btn {
    display: inline-block;
    cursor: pointer;
    & + .custom-icon-btn {
        margin-left: 5px;
    }
    .MuiButton-root {
        min-width: 30px !important;
        padding: 8px 8px !important;
    }
}

.btninActive {
    height: 43px !important;
    width: 100% !important;
}
.btnActive {
    height: 43px !important;
    border: 1px solid green !important;
    width: 100% !important;
}
.btnActiveNo {
    height: 43px !important;
    border: 1px solid red !important;
    width: 100% !important;
}
.btnActiveNA {
    margin: 8px !important;
    height: 43px !important;
    border: 1px solid orange !important;
    width: 100% !important;
}

.failButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .failIcon {
            color: #c5bfbf;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid red !important;
        min-width: 30px !important;
        .failIcon {
            color: red;
        }
    }
}

.passButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .doneIcon {
            color: #c5bfbf;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid green !important;
        min-width: 30px !important;
        .doneIcon {
            color: green;
        }
    }
}

.uploadWithBadgeButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .doneIcon {
            color: #c5bfbf;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid #3f51b5 !important;
        min-width: 30px !important;
        .doneIcon {
            color: #3f51b5;
        }
    }
}

.naButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .naIcon {
            color: #c5bfbf;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid blue !important;
        min-width: 30px !important;
        .naIcon {
            color: blue;
        }
    }
}

.mButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .naIcon {
            color: #c5bfbf;
            font-size: 18px;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid #ff8503 !important;
        min-width: 30px !important;
        .naIcon {
            color: #ff8503;
            font-size: 18px;
        }
    }
}

.rButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        border: 1px solid #c5bfbf !important;
        .naIcon {
            color: #c5bfbf;
            font-size: 18px;
        }
    }
    .btnCheckbox {
        height: 33px !important;
        border: 1px solid red !important;
        min-width: 30px !important;
        .naIcon {
            color: red;
            font-size: 18px;
        }
    }
}

.autocomplete {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
        padding: 2.5px 4px;
    }
    .MuiFormControl-marginNormal {
        margin-top: 0px;
        margin-bottom: 8px;
    }
}

.basicButton {
    .MuiButton-fullWidth {
        width: 35px;
    }
    .btnWithoutCheckbox {
        height: 33px !important;
        min-width: 30px !important;
        .naIcon {
            color: #c5bfbf;
            font-size: 18px;
        }
    }
    .btnCheckbox1 {
        height: 33px !important;
        border: 2px solid grey !important;
        min-width: 30px !important;
        .naIcon {
            color: grey;
            font-size: 18px;
        }
    }
    .btnCheckbox2 {
        height: 33px !important;
        border: 2px solid green !important;
        min-width: 30px !important;
        .naIcon {
            color: green;
            font-size: 18px;
        }
    }
    .btnCheckbox3 {
        height: 33px !important;
        border: 2px solid red !important;
        min-width: 30px !important;
        .naIcon {
            color: red;
            font-size: 18px;
        }
    }
    .btnCheckbox4 {
        height: 33px !important;
        border: 2px solid red !important;
        min-width: 30px !important;
        .naIcon {
            color: red;
            font-size: 18px;
        }
    }
    .btnCheckbox5 {
        height: 33px !important;
        border: 2px solid #ffbf00 !important;
        min-width: 30px !important;
        .naIcon {
            color: #ffbf00;
            font-size: 18px;
        }
    }
}

.app-btn-group {
    .app-select-btn-grp {
        width: 100% !important;
        button {
            text-transform: unset;
            word-wrap: break-word;
            // white-space: nowrap;
            &.Mui-selected {
                color: white;
                background-color: rgb(83, 175, 80);
            }
        }
    }
}

.chip-without-lable {
    margin-right: -20px !important;
}

.file-upload-badge {
    margin-top: 10px;
    .MuiBadge-badge {
        height: 17px;
        min-width: 17px;
        top: 0;
        right: 0;
        transform: scale(1) translate(39%, -43%);
        transform-origin: 100% 0%;
    }
}
