.tab-component {
    .tab {
        justify-content: 'center';
        flex-grow: '0';
        .MuiTab-root {
            font-size: 0.8rem;
            padding: 8px 13px;
            text-transform: none;
        }
    }
}
