.alert-container {
    .MuiAlert-root {
        padding: 8px 8px;
    }
    .MuiAlert-message {
        padding: 4px 0px;
    }
    .alert-items {
        margin-top: 5px;
    }
}
