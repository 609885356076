.grid-style {
    outline: 1px;
    outline-style: solid;
    outline-color: rgba(224, 224, 224, 1);
    padding-left: 5px !important;
}
.gray-grid {
    background-color: #f9f9f9;
}
.white-grid {
    background-color: #ffffff;
}
.grid-title-row {
    margin: 5px 5px 5px 0px !important;
    .MuiButton-root {
        text-transform: none !important;
        font-size: 0.7rem;
    }
}

.stepTest {
    margin-right: auto !important;
}

.Stepper-label {
    // cursor: pointer;
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 0px;
    }
}

.signatureCanvas {
    background-color: aquamarine;
    border: '1px solid black ' !important;
}

.sigContainer {
    width: 100%;
    height: 165px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid;
}

.sigPad {
    width: 100%;
    height: 100%;
}

@media (max-width: 767px) {
    .stepper-label-to-hide {
        display: none !important;
    }
}
