.vorReporting {
    .vortype-container {
        width: 100%;
        display: flex;
        //  padding: 12px;
        //  background: rgb(255, 255, 255);
        background: '#8080800d';
        color: black;
        .vortype-data {
            text-overflow: ellipsis;
            white-space: 'nowrap';
            width: 100%;
            overflow: hidden;
        }
    }
    .headerName {
        text-align: left !important;
        padding: 0px 20px !important;
        font-family: 'Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
        font-weight: 500;
    }
    .header {
        padding: 0px 10px 0px 0px;
    }
    .headerTotal {
        font-size: 40px;
        margin-bottom: 2px;
        font-family: '"Public Sans", sans-serif';
        font-weight: 600;
        letter-spacing: 1px;
    }
    .categorybar {
        border-left: 10px solid #00f433;
        margin-right: 2;
        padding: 5px 10px 0px 10px;
    }
    .seprationBar {
        border-left: 1px dotted black !important;
        padding: 5px 10px 0px 10px;
    }
    .depotbar {
        border-left: 10px solid #00f433;
        padding: 5px 10px 0px 10px;
        width: '100%';
    }
    .depotSection {
        padding-bottom: 10px;
        text-align: left;
    }
    .depotNameformate {
        font-size: 14px;
        margin-bottom: 6px;
        font-family: '"Public Sans", sans-serif';
    }
    .depotCount {
        font-size: 30px;
        font-weight: 600px;
        font-family: '"Public Sans", sans-serif';
    }
}
