.speed-dial {
    .speed-dial-body {
        .MuiFab-root {
            width: 41px !important;
            height: 41px !important;
        }
        .MuiSvgIcon-root {
            font-size: 1.2rem !important;
        }
    }
}
