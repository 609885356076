.job-query-modal {
    .MuiPaper-root {
        width: 600px;
    }

    .notes-container {
        height: 300px;
        border: 1px solid lightgray;
        padding: 5px;
        border-radius: 4px;
        overflow-y: auto;
        font-style: italic;
        margin-bottom: 20px;
        .single-note {
            padding: 5px;
            .msg {
                font-size: 14px;
            }
            .footer {
                font-size: 12px;
                color: gray;
                text-align: right;
            }
        }
        .single-note + .single-note {
            border-top: 1px solid lightgray;
        }
    }
}
.custom-scroll {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

