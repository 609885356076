.all_schedules {
    position: relative;
    .date_range {
        // height: 158px;
        // position: relative;
        // top: 11px;
        // right: 0px;
        // box-sizing: border-box;
        .date_items {
            display: inline-block;
            padding: 5px;
        }
    }
    .date_range_mob {
        display: none;
        position: relative;
        top: 30px;
        right: 0px;
    }
    .grid-style {
        outline: 1px !important;
        outline-style: solid !important;
        outline-color: rgba(224, 224, 224, 1) !important;
        padding-left: 5px !important;
    }

    .gray-grid {
        background-color: #f9f9f9 !important;
    }

    @media screen and (max-width: 1200px) {
        div.date_range {
            display: block;
        }
        div.date_range_mob {
            display: none !important;
        }
    }
    @media screen and (max-width: 700px) {
        div.date_range_mob {
            width: 200px;
            position: relative;
            top: 0px;
        }
    }
}
