.service-agent-screen {
    .edit-service-agent-btn {
        margin: 0px 0px 10px 0px !important;
    }
    .service-agent-label {
        .MuiTypography-caption {
            color: gray;
        }
    }
    .grid-style {
        outline: 1px !important;
        outline-style: solid !important;
        outline-color: rgba(224, 224, 224, 1) !important;
        padding-left: 5px !important;
    }
    
    .gray-grid {
        background-color: #f9f9f9 !important;
    }
    
    .white-grid {
        background-color: #ffffff !important;
    }
    
    .grid-title-row {
        margin: 5px 5px 5px 0px !important;
        .MuiButton-root {
            text-transform: none !important;
            font-size: 0.65rem;
        }
    }
}
