.schedule_screen {
  margin: 0px;
  .schedule_add_btn {
    position: absolute;
    top: 245px;
    left: 500px;
  }
  .btn_space {
    margin-right: 3px !important;
  }
}
.typo {
  padding: 8px;
}
.yes_no_container {
  span.yes_no {
    display: inline-block !important;
    margin-right: 10px;
    width: 70px;
    margin-right: 10px;
  }
  .MuiButton-root {
    height: 40px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
}

.notes_grid {
  min-width: 350px;
  margin-bottom: 20px !important;
}

.schedule_s_btn {
  margin-right: 20px !important;
}

.job-book-screen {
  padding: 12px;
  justify-content: center;
  .label {
    height: 100%;
  }
  .yes_no {
    display: inline-block !important;
  }
  .MuiButton-root {
    height: 40px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin: 0px !important;
  }
  .btn-spacing {
    padding: 10px;
    box-sizing: border-box;
  }
}
