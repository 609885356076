.user-screen {
    .lock-btn {
        display: inline-block;
        .MuiButton-root {
            min-width: 29px !important;
            padding: 8px 0px !important;
            margin-left: 2px !important;
        }
    }
}
