.pieChart {
    height: 230px;
    width: 100%;
    background-color: #f9f9f9;
}

.barchart {
    height: 230px;
    width: 100%;
    background-color: #f9f9f9;
}
// .barHeading {
//     margin-left: 20 !impotant;
// }
.job_update_note {
    height: calc(100vh - 230px) !important;
    width: calc(100vh - 230px) !important;
    @media (max-width: 400px) and (min-width: 200px) {
        height: '80px' !important;
        width: '230px' !important;
    }
}
