table.gj-grid-bootstrap thead th {
    background-color: #f5f5f5 !important;
    vertical-align: middle;
}
.gj-grid tbody {
    // height: calc(100vh - 295px) !important;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.gj-grid tfoot th {
    padding: 0px !important;
    line-height: 0px !important;
}
.gj-button-md.gj-button-md-icon {
    top: 0px !important;
}

.gj-md-spacer-32 {
    line-height: 0px !important;
}

table.gj-grid tfoot DIV[data-role='display'] {
    align-items: center;
    margin: 0px !important;
    padding: 5px !important;
    font-weight: 600;
}

.gj-grid-md tfoot tr[data-role='pager'] .gj-dropdown-md {
    top: 4px;
}

.gj-grid-md th {
    vertical-align: bottom;
    font-weight: 500;
    font-size: 0.83rem !important;
    line-height: 1px;
    height: 26px !important;
    color: rgb(15, 15, 15) !important;
    border-right: 1px solid #9e9e9e !important;
}
.gj-grid-md {
    font-size: 0.77rem !important;
}
.gj-grid-md tbody tr {
    height: 26px !important;
}
.gj-grid-md td {
    padding: 0px 4px !important;
    height: 16px !important;
    font-size: 0.875rem !important;
}
.gj-width-full {
    width: 100%;
    height: 22px;
}
table.gj-grid-md thead th [data-role='sorticon'] i.gj-icon,
table.gj-grid-md thead th [data-role='sorticon'] i.material-icons {
    top: 7px;
    padding-left: 3px;
}
.gj-grid-md tfoot tr[data-role='pager'] .gj-dropdown-md [role='presenter'] {
    font-size: 16px !important;
    margin-top: -13px;
}

.grid-filter-select-th {
    padding: 10px 6px 12px 5px !important;
}

TABLE.gj-grid THEAD TH {
    text-overflow: unset !important;
    padding: 12px 13px 12px 5px;
}

table.gj-grid tfoot DIV[data-role='display'] {
    text-indent: 13px !important;
    margin-top: 9px;
}

.gj-button-md.gj-button-md-icon {
    top: -14px;
}
.black-iconmt {
    font-size: 25px;
    font-weight: bold;
    color: black;
}

.userSign {
    .signatureCanvas {
        border: 1px solid black;
        width: 100%;
        height: 140px;
        margin-top: 9px;
    }
}
.grid-filter-select {
    height: 28px;
}

// .gj-grid-md th:first-of-type {
//     padding: 12px 5px 12px 5px;
// }

.gridHeader {
    font-size: 40px;
    font-weight: bold !important;
    background-color: azure;
}
.abc {
    background-color: red;
}
