.customer-dashboard {
    margin: 10px 10px;
    .card {
        // height: 200px;
        height: inherit;
        display: 'flex';
        flex-direction: 'column';
        color: white;
    }
    .cardDesign {
        position: relative;
        border-bottom: 5px solid #009432;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .red_cardDesign {
        position: relative;
        border-bottom: 5px solid #ff4757;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .yellow_cardDesign {
        position: relative;
        // z-index: 1000;
        border-bottom: 5px solid #ffc312;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .numaricData {
        font-size: 35px;
    }

    .iconStyle {
        background-color: #009432;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 48%;
        transform: translate(-50%);
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 30px;
        box-shadow: 0px 0px 0px 8px rgba(0, 148, 50, 0.53);
        -webkit-box-shadow: 0px 0px 0px 8px rgba(0, 148, 50, 0.53);
        -moz-box-shadow: 0px 0px 0px 8px rgba(0, 148, 50, 0.53);
    }
    .red_card_icon {
        background-color: #ff4757;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 48%;
        transform: translateX(-50%);
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 30px;
        box-shadow: 0px 0px 0px 8px rgba(255, 32, 54, 0.53);
        -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 32, 54, 0.53);
        -moz-box-shadow: 0px 0px 0px 8px rgba(255, 32, 54, 0.53);
    }
    .yellow_card_icon {
        background-color: #ffc312;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 48%;
        transform: translate(-50%);
        overflow: visible;
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 30px;
        box-shadow: 0px 0px 0px 8px rgba(255, 236, 30, 0.75);
        -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 236, 30, 0.75);
        -moz-box-shadow: 0px 0px 0px 8px rgba(255, 236, 30, 0.75);
    }
    //purple Card Design
    .purple_cardStyle {
        position: relative;
        // z-index: 1000;
        border-bottom: 5px solid #7d38df;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .purple_iconStyle {
        background-color: #7d38df;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 48%;
        transform: translate(-50%);
        overflow: visible;
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 30px;
        box-shadow: 0px 0px 0px 8px rgba(200, 61, 255, 0.596);
        -webkit-box-shadow: 0px 0px 0px 8px rgba(162, 49, 255, 0.75);
        -moz-box-shadow: 0px 0px 0px 8px rgba(198, 55, 255, 0.637);
    }
    .cardMedia {
        padding-top: '56.25%'; // 16:9
    }
    .cardContent {
        flex-grow: 1;
        text-align: center;
        margin-top: 18px;
    }
    .cardGrid {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .upper {
        position: relative;
        height: 175px;
        // padding: 10px;
        padding: 6px 25px 0px 15px;
    }
    .gridHeader {
        margin: 8px;
    }
    // .mainContainer {
    //     box-shadow: -4px 0px 3px 1px rgba(0, 0, 0, 0.3);
    // }
    // .mainContainertwo {
    //     box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    // }
    .speedoMeterContainer {
        margin-top: 60px;
    }
    @media screen and (max-width: 1280px) {
        .speedoMeterContainer {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 960px) {
        .speedoMeterContainer {
            margin-top: 0px;
        }
        .mainContainer {
            margin-bottom: 15px;
        }
    }
}
