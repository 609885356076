.admin-dashboard {
    .card {
        // height: 200px;
        height: inherit;
        display: 'flex';
        flex-direction: 'column';
        color: white;
    }
    .cardMedia {
        padding-top: '56.25%'; // 16:9
    }
    .bgColor {
        background-color: #cfdce2;
    }
    //normal card design
    .card_Style {
        position: relative;
        border-bottom: 5px solid #009432;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .icon_Style {
        background-color: #009432;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 40px;
        box-shadow: 0px 0px 0px 11px rgba(0, 148, 50, 0.53);
        -webkit-box-shadow: 0px 0px 0px 11px rgba(0, 148, 50, 0.53);
        -moz-box-shadow: 0px 0px 0px 11px rgba(0, 148, 50, 0.53);
    }
    // Red Card Design
    .red_cardStyle {
        position: relative;
        border-bottom: 5px solid #ff4757;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .red_iconStyle {
        background-color: #ff4757;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 40px;
        box-shadow: 0px 0px 0px 11px rgba(255, 32, 54, 0.53);
        -webkit-box-shadow: 0px 0px 0px 11px rgba(255, 32, 54, 0.53);
        -moz-box-shadow: 0px 0px 0px 11px rgba(255, 32, 54, 0.53);
    }
    // Yellow Card Design
    .yellow_cardStyle {
        position: relative;
        // z-index: 1000;
        border-bottom: 5px solid #ffbb00;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .yellow_iconStyle {
        background-color: #ffbb00;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        overflow: visible;
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 40px;
        box-shadow: 0px 0px 0px 11px rgba(252, 255, 73, 0.836);
        -webkit-box-shadow: 0px 0px 0px 11px rgba(255, 235, 52, 0.829);
        -moz-box-shadow: 0px 0px 0px 11px rgba(246, 250, 33, 0.788);
    }
    //purple Card Design
    .purple_cardStyle {
        position: relative;
        // z-index: 1000;
        border-bottom: 5px solid #7d38df;
        color: rgb(0, 0, 0);
        top: 20px;
        background-color: rgba(235, 235, 235, 0.637);
        box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px -3px 11px 4px rgba(0, 0, 0, 0.23);
    }
    .purple_iconStyle {
        background-color: #7d38df;
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        overflow: visible;
        padding: 5px;
        border-radius: 50px;
        color: white;
        font-size: 40px;
        box-shadow: 0px 0px 0px 11px rgba(200, 61, 255, 0.596);
        -webkit-box-shadow: 0px 0px 0px 11px rgba(162, 49, 255, 0.75);
        -moz-box-shadow: 0px 0px 0px 11px rgba(198, 55, 255, 0.637);
    }
    .upper {
        position: relative;
        height: 200px;
        margin-top: 15px;
    }
    .numaricData {
        font-size: 40px;
    }
    .cardContent {
        flex-grow: 1;
        text-align: center;
        margin-top: 20px;
    }
    .cardGrid {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
